import * as React from 'react';

import classNames from 'classnames'

import DocBookmark from './Bookmark'
import DocInlineText from './InlineText'

interface DocCheckboxProps extends CheckboxNode, EveryNodeArgs {};

export default function DocCheckbox(props: DocCheckboxProps) {
  const onChanged = (e) => {
    props.currentUser.addState({[e.target.id]: {"value": e.target.checked}});
  };

  const onClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={classNames("doc-checkbox", {"inactive": props.inactiveBoolean})}>
      <input type="checkbox" id={props.uid} disabled={props.inactiveBoolean} checked={props.value} onChange={onChanged} onClick={this.onClick}/>
      <label htmlFor={props.uid} onClick={onClick}>
        <span className="doc-checkbox-outer"><span className="doc-checkbox-inner"></span></span>
        <span className="doc-checkbox-title">
          <DocInlineText content={props.content} uid={props.uid} contentKey="content" editState={props.editState}/>
        </span>
      </label>
      <DocBookmark {...props} />
    </div>
  );
}
