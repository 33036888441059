import * as React from 'react';

import GenButton from "../Gen/Button";
import GenModal from "../Gen/Modal";

interface DocQuestionReportButtonProps {};

export default function DocQuestionReportButton(props: DocQuestionReportButtonProps) {
  const [openModal, setOpenModel] = React.useState(false);
  const [responseText, setResponseText] = React.useState("");

  const submitResponse = () => {
    setOpenModel(false);
  }

  const cancelResponse = () => {
    setOpenModel(false);
  }

  let modal
  if (openModal) {
    modal = <GenModal open>
      <div className="gen-modal-panel">
          <h3>How can this question be improved?</h3>
          <textarea
            value={responseText}
            onChange={(e) => setResponseText(e.target.value)}
            placeholder="Enter Answer Here"
            rows={4}
          />
          <div className="flex gap-3">
            <GenButton
              name="Submit"
              onClick={submitResponse}
            />
            <GenButton
              name="Cancel"
              onClick={cancelResponse}
            />
          </div>
        </div>
    </GenModal>
  }

  return (
    <div className="absolute right-2 top-1">
      <button className="flex items-center gap-1 hover:opacity-80" onClick={() => setOpenModel(true)}>
        <i className="fa fa-exclamation-circle text-2xl"></i>
        <span className="font-bold pt-1">REPORT</span>
      </button>
      {modal}
    </div>
  )
}
