import * as React from 'react';

import GenLoadingSpinner from './LoadingSpinner'

interface GenPostButtonType {
  disabled?: boolean;
  highlighted?: boolean;
  small?: boolean;
  loading?: boolean;
  url: string;
  data: any; // TODO: GET RID OF any
  name: string;
}

export default function GenPostButton(
  {
    disabled,
    highlighted,
    small,
    loading,
    url,
    data,
    name,
  }: GenPostButtonType,
) {
  let classes = "gen-button";

  if (disabled) {
    classes += " disabled";
  }

  if (highlighted) {
    classes += " highlighted";
  }

  if (small) {
    classes += " small";
  }

  if (loading) {
    return(
      <div className={classes}>
        <span className="button-name"><GenLoadingSpinner/></span>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        <form action={url} method='POST'>
          <input type='hidden' name='authenticity_token' value={(document.head.querySelector("meta[name='csrf-token']") as HTMLMetaElement).content} />
          {Object.keys(data).map((key, index) => {
            return <input type='hidden' key={key} name={key} value={JSON.stringify(data[key])} />;
          })}
          <input key='submit' type='submit' value={name} />
        </form>
      </div>
    );
  }
}
