import * as React from 'react';

import classNames from 'classnames'

import DocBookmark from './Bookmark'
import DocInlineText from './InlineText'

import { performActions } from '../../src/doc_actions'

interface DocButtonProps extends ButtonNode, EveryNodeArgs {};

export default function DocButton(props: DocButtonProps) {
  const onClick = () => {
    if (props.inactiveBoolean) {return null;}

    performActions(props.onClick, props.currentUser)
  };

  return (
    <div className={classNames("doc-button-slice", {"inactive": props.inactiveBoolean})}>
      <button className="text-white bg-sky-600 hover:bg-sky-500 p-2 doc-button" onClick={onClick}>
        <DocInlineText content={props.title} uid={props.uid} contentKey="title" editState={props.editState} preventEditing/>
      </button>
      <DocBookmark {...props} />
    </div>
  );
}
