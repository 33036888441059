import * as React from 'react';

interface DocExtraProps {
  editState: EditState;
  subtype: string;
  content: string;
};

export default function DocExtra(props: DocExtraProps) {
  const {
    editState,
    subtype,
    content,
  } = props;

  if (!editState.isEditor) { return(null); }

  switch(subtype){
    case "error":
      return <p className="doc-extra doc-extra-error" dangerouslySetInnerHTML={{__html: content}}/>;
    case "warning":
      return <p className="doc-extra doc-extra-warning" dangerouslySetInnerHTML={{__html: content}}/>;
    default:
      return <p className="doc-extra doc-extra-unknown" dangerouslySetInnerHTML={{__html: content}}/>;
  }
}
