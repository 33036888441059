import * as React from 'react';

import * as R from 'ramda'

import classNames from 'classnames';

import connectAiChatSubscription from "../../channels/ai_chat_channel"

import GenLoadingSpinner from "../Gen/LoadingSpinner";
import GenButton from "../Gen/Button";

function MessageBar(props) {
  const {
    name,
    imageSrc,
    content,
    disabled,
  } = props;

  let renderedData = [
    <div key="messageName">
      <div style={{backgroundColor: name === "YOU" ? "#0570C7" : "#FC8181" }} className={`h-20 w-20 rounded-full flex justify-center items-center overflow-hidden ${disabled ? 'opacity-70' : ''}`}>
        {imageSrc ? <img src={imageSrc} className="min-w-full min-h-full w-auto" /> : <span className="self-center text-center flex-1 text-white"><strong>{name}</strong></span>}
      </div>
    </div>,
    <div key="message" style={{backgroundColor: name === "YOU" ? "#DAEEFF" : "#eee" }} className="flex-1 p-3 rounded-lg self-center">
      {content}
    </div>
  ]

  if (name === "YOU") { renderedData.reverse(); }

  return (
    <div className="flex gap-x-2 mb-3">
      {renderedData}
    </div>
  )
}

const MAX_COUNT = 20

interface DocOpenAiChatProps extends OpenAiChatNode, EveryNodeArgs {};;

interface DocOpenAiChatState {

};

export default class DocOpenAiChat extends React.Component<DocOpenAiChatProps, DocOpenAiChatState> {
  state = {
    loading: false,
    messages: [],
    newUserMessage: "",
  };

  private aiChatSubscription: any;

  componentDidMount() {
    const {
      messages = [],
      startWithAssistant,
    } = this.props;

    if (startWithAssistant && messages.length === 0) {
      this.submitMessages();
    }
  }

  componentWillUnmount() {
    if(this.aiChatSubscription) {
      this.aiChatSubscription.unsubscribe()
      this.aiChatSubscription = null
    }
  }

  submitNewUserMessage() {
    const {
      newUserMessage,
    } = this.state;

    const userMessage = {
      role: "user",
      content: newUserMessage,
    }

    this.setState({
      newUserMessage: "",
    })

    this.submitMessages([userMessage]);
  }

  submitMessages(newMessages=[]) {
    const {
      uid,
      currentUser,
      messages = [],
      aiInstructions,
      currentActivityUserState,
      editState,
    } = this.props;

    if (editState.isEditor) { return }

    let compiledAiInstructions = aiInstructions;

    if(currentActivityUserState) {
      const regex = /\{\{\s*(\w+)\s*\}\}/g
      const regexMatches = compiledAiInstructions.matchAll(regex)

      for (const match of regexMatches) {
        const fullMatch = match[0]
        const stateKey = match[1]

        const renderedMessages = (currentActivityUserState.state[stateKey]?.messages?.value || [])
          .map((message) => `${message.role}: ${message.content}`)
          .join('\n\n');

        compiledAiInstructions = compiledAiInstructions.replace(fullMatch, renderedMessages);
      }
    }

    const introMessage = {
      role: "system",
      content: compiledAiInstructions
    }

    this.setState({
      loading: true,
    })

    const allMessages = [...messages, ...newMessages]
    if (newMessages.length > 0) {
      currentUser.addState({[uid]: {"messages": allMessages}});
    }

    if (!this.aiChatSubscription) {
      this.aiChatSubscription = connectAiChatSubscription({
        chatId: `${currentUser.id}::${currentActivityUserState?.id}::${uid}`,
        onMessageReceived: this.receiveMessage.bind(this)
      })
    }

    setTimeout(() => this.aiChatSubscription.chat([introMessage, ...allMessages]), 1000)
  }

  receiveMessage(message) {
    const {
      uid,
      currentUser,
      messages = [],
    } = this.props;

    currentUser.addState({[uid]: {"messages": messages.concat(message)}});

    this.setState({
      loading: false,
      newUserMessage: "",
    })
  }

  restartChat() {
    const {
      uid,
      currentUser,
    } = this.props;

    this.setState({
      newUserMessage: "",
    })

    currentUser.addState({[uid]: {"messages": []}});
  }

  onEnterPress(e) {
    const {
      inactive,
    } = this.props;

    const {
      loading,
      newUserMessage,
    } = this.state;

    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();

      if (!(loading || inactive || this.userMessageCount() >= MAX_COUNT || newUserMessage.length === 0)) {
        this.submitNewUserMessage();
      }
    }
  }

  displayNameFromAiRole(role) {
    const {
      userDisplayName,
      assistantDisplayName,
    } = this.props;

    if (role === "assistant") {
      return assistantDisplayName || "Assistant"
    } else {
      return userDisplayName || "YOU"
    }
  }

  displayImageSrcFromAiRole(role) {
    const {
      assistantDisplayImage,
      userDisplayImage,
      currentVersion,
    } = this.props;

    if (role === "assistant") {
      return assistantDisplayImage && `${currentVersion.aws_folder_url}/compressed/images/${assistantDisplayImage}`;
    } else {
      return userDisplayImage && `${currentVersion.aws_folder_url}/compressed/images/${userDisplayImage}`;
    }
  }

  userMessageCount() {
    const {
      messages = [],
    } = this.props;

    return messages.filter((message) => message.role === "user").length
  }

  render() {
    const {
      uid,
      messages = [],
      inactive,
      inactiveBoolean,
      editState,
      placeholderText,
    } = this.props;

    const {
      loading,
      newUserMessage,
    } = this.state;

    const userMessageCount = this.userMessageCount()
    const disableChat = editState.isEditor || loading || inactive || userMessageCount >= MAX_COUNT
    const disableSubmitChat = disableChat || newUserMessage.length === 0

    return (
      <div className={classNames("doc-text-input", {"inactive": inactiveBoolean})}>
        <div>
          { messages.map((message, i) => (
            <MessageBar
              key={`message-${i}`}
              name={this.displayNameFromAiRole(message.role)}
              imageSrc={this.displayImageSrcFromAiRole(message.role)}
              content={
                message.content.split(/\n/).map((paragraph, i) => <p key={i} className="[&:not(:last-child)]:mb-3">{paragraph}</p>)
              }
            />
          ))}
          { loading ? (
            <MessageBar
              name={this.displayNameFromAiRole("assistant")}
              imageSrc={this.displayImageSrcFromAiRole("assistant")}
              content={
                <GenLoadingSpinner/>
              }
            />
          ) : (
            <MessageBar
              name={this.displayNameFromAiRole("user")}
              imageSrc={this.displayImageSrcFromAiRole("user")}
              disabled={disableChat}
              content={
                <div className="relative">
                  <textarea
                    cols={40}
                    rows={4}
                    value={newUserMessage}
                    onChange={(e) => this.setState({newUserMessage: e.target.value})}
                    onKeyDown={this.onEnterPress.bind(this)}
                    disabled={disableChat}
                    autoFocus={!disableChat}
                    placeholder={disableChat ? "" : (!placeholderText || R.isEmpty(placeholderText) ? "Type a message..." : placeholderText)}
                  />
                  <div className="absolute right-1 bottom-3">
                    <GenButton
                      name={<i className="fa fa-arrow-right" />}
                      highlighted
                      disabled={disableSubmitChat}
                      onClick={() => this.submitNewUserMessage()}
                    />
                  </div>
                </div>
              }
            />
          )}
          <div className="flex justify-end gap-2 items-center">
            <GenButton
              name="Restart"
              highlighted
              disabled={loading || inactive || messages.length === 0}
              small
              onClick={() => this.restartChat()}
              confirmMessage="Are you sure you want to restart?"
            />
            {userMessageCount >= MAX_COUNT ? `Maximum Reached` : `${userMessageCount + 1} of ${MAX_COUNT}`}
          </div>
        </div>
        { editState.isEditor && (
          <div style={{position: "absolute", top: 0, right: "-20px"}}>
            <GenButton
              name={<i className="fa fa-cog"/>}
              highlighted
              disabled={!editState.isEditing}
              small
              onClick={() => {
                editState.setEditMetadata(uid);
                editState.setCurrentlyEditing(null);
              }}
            />
          </div>
        )}
      </div>
    )
  }
}
