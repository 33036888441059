import * as React from 'react';

import GenButton from "../Gen/Button";
import GenMediaDuplicationTool from "../Gen/MediaDuplicationTool";
import GenMediaUploadButton from "../Gen/MediaUploadButton";
import GenModal from "../Gen/Modal";

import DocVideoJWPlayer from "./VideoJWPlayer";

import {capitalize} from "lodash";

import {
  setNodeValueByUIDAndKey,
  deleteNodeByUID,
} from "../../src/doc_helpers";

interface DocMediaModalProps {
  currentDocument: CurrentDocument;
  currentVersion: CurrentVersion;
  editState: EditState;
  readDocument: () => void;
  currentUser: CurrentUser;
  currentRoleDefinition: CurrentRoleDefinition;
}

export default function DocMediaModal(props: DocMediaModalProps) {
  const {
    currentDocument,
    currentVersion,
    editState,
  } = props;

  if (!(editState.currentlyEditing && editState.currentlyEditing.menu === "mediaMenu")) { return null; }

  const {mediaType} = editState.currentlyEditing;

  const mediaItems = mediaType === "video" ? currentDocument.media_assets : currentDocument[`${mediaType}s`];
  const renderedMediaItems = (mediaItems || []).map((mediaItem) => {
    const newURI = mediaType === "video" ? mediaItem.media_key : mediaItem;

    const selectMediaItem = () => {
      if (editState.currentlyEditing.isCoverImage) {
        editState.setEditFieldsVersion({cover_image: newURI});
      } else {
        editState.modifyEditJSON((json) => setNodeValueByUIDAndKey(json, editState.currentlyEditing.uid, "uri", newURI));
      }
      editState.setCurrentlyEditing(null);
    };

    let preview; let displayName; let
      extraControls;
    switch (mediaType) {
      case "audio":
        preview = <i className="fa fa-music" />;
        displayName = mediaItem;
        break;
      case "image":
        preview = <img src={`${currentVersion.aws_folder_url}/compressed/${mediaType}s/${mediaItem}`} />;
        displayName = mediaItem;
        break;
      case "video":
        preview = (
          <DocVideoJWPlayer
            videoKey={`media-menu-${mediaItem.media_key}`}
            videoURL={`https://d3o9veyy7wy4ai.cloudfront.net/${mediaItem.media_key}`}
            hasCaptions={!!mediaItem?.three_play_media_captions_processed_at}
            shouldLoop={mediaItem.is_silent}
          />
        );

        displayName = `${mediaItem.name} (ID: ${mediaItem.id})`;
        extraControls = (
          <>
            <GenButton name="Download mp4" href={mediaItem.main_mp4_url} target="_blank" highlighted small />
            <GenButton name="Download Original" href={mediaItem.original_url} target="_blank" highlighted small />
            {!!mediaItem.three_play_media_captions_processed_at && (
            <>
              <GenButton name="Download vtt" href={mediaItem.vtt_captions_url} target="_blank" highlighted small />
              <GenButton name="Download srt" href={mediaItem.srt_captions_url} target="_blank" highlighted small />
            </>
            )}
          </>
        );
    }

    return (
      <div key={newURI}>
        {preview}
        <h6 style={{margin: "5px 0"}}>{displayName}</h6>
        <div className="flex gap-2">
          <GenButton name="Select" onClick={selectMediaItem} highlighted small />
          {extraControls}
        </div>
        <hr className="my-6" />
      </div>
    );
  });

  let ultrasoundUploadButton;
  let extraBottomTools;
  if (mediaType === "video") {
    ultrasoundUploadButton = (
      <GenMediaUploadButton
        documentId={currentDocument.id}
        mediaType={mediaType}
        editState={editState}
        onSuccess={() => props.readDocument()}
        titleEnding="Loop"
        mediaOptions={{is_silent: true}}
      />
    );

    extraBottomTools = (
      <GenMediaDuplicationTool
        documentId={currentDocument.id}
        onSuccess={() => props.readDocument()}
      />
    );
  }

  const deleteMediaFunction = () => {
    editState.modifyEditJSON((json) => deleteNodeByUID(json, editState.currentlyEditing.uid));
    editState.setCurrentlyEditing(null);
  };

  return (
    <GenModal open>
      <div className="gen-modal-panel">
        <div className="clearfix">
          <h4 style={{float: "left"}}>{capitalize(mediaType)}s</h4>
          <div style={{float: "right"}} className="flex gap-2">
            <GenButton
              name="Delete"
              onClick={deleteMediaFunction}
              highlighted
            />
            <GenMediaUploadButton
              documentId={currentDocument.id}
              mediaType={mediaType}
              editState={editState}
              onSuccess={() => props.readDocument()}
            />
            {ultrasoundUploadButton}
            <GenButton name={<i className="fa fa-times" />} onClick={() => editState.setCurrentlyEditing(null)} highlighted />
          </div>
        </div>
        <hr className="my-6" />
        <div>
          {renderedMediaItems}
        </div>
        {extraBottomTools}
      </div>
    </GenModal>
  );
}
