import * as React from 'react';

import DocBookmark from './Bookmark'
import DocListItem from './ListItem'

interface DocNumberedListProps extends NumberedListNode, EveryNodeArgs {};

export default function DocNumberedList(props: DocNumberedListProps) {
  let listItems = props.content.map(listItem => {
    return (
      <DocListItem
        key={listItem.uid}
        {...listItem}
        currentDocument={props.currentDocument}
        currentVersion={props.currentVersion}
        currentRoleDefinition={props.currentRoleDefinition}
        currentDocumentSetDocumentRelation={props.currentDocumentSetDocumentRelation}
        currentUser={props.currentUser}
        displayState={props.displayState}
        editState={props.editState}
      />
    );
  });

  return (
    <ol className="doc-numbered-list">
      {listItems}
      <DocBookmark {...props} />
    </ol>
  );
}
