import * as React from 'react';

import DocBookmark from './Bookmark'
import DocExtra from './Extra'
import DocInlineText from './InlineText'

interface DocAudioProps extends AudioNode, EveryNodeArgs {};

export default function DocAudio(props: DocAudioProps) {
  const {
    currentVersion,
    editState,
    source,
    title,
    uid,
    uri,
  } = props;

  let renderedTitle = <figcaption>
    <DocInlineText
      content={title}
      uid={uid}
      contentKey="title"
      editState={editState}
    />
  </figcaption>;

  switch (source) {
    case "local":
      return (
        <div className="doc-audio doc-audio-local">
          <audio preload="none" controls>
            <source src={currentVersion.aws_folder_url + "/original/audios/" + uri} />
          </audio>
          {renderedTitle}
          <DocBookmark {...props} />
        </div>
      );
    case "soundcloud":
      return (
        <div className="doc-audio doc-audio-soundcloud">
          <iframe width="100%" height="450" scrolling="no" frameBorder="no" src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + uri + "&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"}></iframe>
          {renderedTitle}
          <DocBookmark {...props} />
        </div>
      );
    default:
      return (
        <DocExtra
          subtype="error"
          content={"Unknown audio source type: " + source}
          editState={editState}
        />
      );
  }
}
