import * as React from 'react';

import * as R from 'ramda'

import {
  setNodeValueByUIDAndKey
} from '../../src/doc_helpers'

import DocBookmark from './Bookmark'
import DocInlineText from './InlineText'

interface DocImageProps extends ImageNode, EveryNodeArgs {
  imagingType?: ImagingType;
  imagingTypes?: Array<ImagingType>;
  inGallery?: boolean;
};

interface DocImageState {
  imageModalOn: boolean;
};

export default class DocImage extends React.Component<DocImageProps, DocImageState> {
  state = {
    imageModalOn: false
  };

  imageURL() {
    switch (this.props.source) {
      case "reeldx":
        return `${this.props.currentVersion.aws_folder_url}/images/${this.props.uri}`
      case "local":
      default:
        return `${this.props.currentVersion.aws_folder_url}/compressed/images/${this.props.uri}`
    }
  };

  onImageEditClick() {
    if (this.props.editState.isEditing) {
      this.props.editState.setCurrentlyEditing({uid: this.props.uid, menu: 'mediaMenu', mediaType: 'image'});
    }
  }

  toggleImageModal() {
    this.setState({imageModalOn: !this.state.imageModalOn});
  };

  handleImagingTypeChange(e) {
    this.props.editState.modifyEditJSON(json =>
      setNodeValueByUIDAndKey(json, this.props.uid, 'imagingType', e.target.value)
    )
  }

  render() {
    let imageModal;
    if (this.state.imageModalOn) {
      imageModal = <div className="image-modal-outter" onClick={this.toggleImageModal.bind(this)}>
        <div className="image-modal-inner">
          <img src={this.imageURL()} alt={this.props.title}/>
          <i className="fa fa-compress doc-image-compress-button"></i>
        </div>
      </div>;
    }

    let image =
      <figure className="doc-image">
        <div onClick={this.toggleImageModal.bind(this)}>
          <img src={this.imageURL()} alt={this.props.title}/>
          <i className="fa fa-expand doc-image-expand-button"></i>
        </div>
        <figcaption>
          <DocInlineText content={this.props.title} uid={this.props.uid} contentKey="title" editState={this.props.editState}/>
        </figcaption>
        {imageModal}
        <DocBookmark {...this.props} />
        {this.props.editState.isEditing &&
         <>
           <i className="fa fa-cog bg-sky-600 p-2 doc-media-edit-button" onClick={this.onImageEditClick.bind(this)}> Edit Image</i>
           {'imagingTypes' in this.props && R.length(this.props.imagingTypes) > 0 &&
            <select value={this.props.imagingType} onChange={this.handleImagingTypeChange.bind(this)} className="tag-select">
              <option value="">Select Imaging Type</option>
              {this.props.imagingTypes.map((t) => <option value={t} key={t}>{t}</option>)}
            </select>
           }
         </>
        }
      </figure>;

    if (this.props.inGallery) {
      return image
    } else {
      return (
        <div className="doc-image-single">
          {image}
        </div>
      )
    }
  }
}
