import * as React from 'react';

import classNames from 'classnames'

import DocBookmark from './Bookmark'

interface DocRatingBarProps extends RatingBarNode, EveryNodeArgs {};

export default function DocRatingBar(props: DocRatingBarProps) {
  const onClick = (clickValue, e) => {
    e.stopPropagation();

    if (props.inactiveBoolean) {return null;}

    props.currentUser.addState({[props.uid]: {"value": props.maxValue - clickValue}});
  };

  let stars = [];
  for (let i = 0; i < props.maxValue; i++) {
    stars.push(
      <span
        className={classNames("doc-rating-bar-star", {"doc-rating-bar-star-active": i >= props.maxValue - props.value})}
        key={i}
        onClick={onClick.bind(this, i)}
      >
        ☆
      </span>
    );
  }

  return (
    <div className={classNames("doc-rating-bar", {"inactive": props.inactiveBoolean})}>
      {stars}
      <DocBookmark {...props} />
    </div>
  );
}
