import * as React from 'react';

import DocBookmark from './Bookmark'
import DocInlineText from './InlineText'

interface DocParagraphProps extends ParagraphNode, EveryNodeArgs {};

export default function DocParagraph(props: DocParagraphProps) {
  return (
    <div className="doc-paragraph">
      <p>
        <DocInlineText content={props.content} uid={props.uid} contentKey="content" editState={props.editState}/>
      </p>
      <DocBookmark {...props} />
    </div>
  );
}
