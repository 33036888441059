import * as React from 'react';

interface GenLoadingSpinnerProps {
  highlighted?: boolean;
}

export default function GenLoadingSpinner(
  {
    highlighted,
  }: GenLoadingSpinnerProps,
) {
  let classes = "gen-loading-spinner";

  if (highlighted) {
    classes += " highlighted";
  }

  return(
    <div className={classes}>
      <div className="bg-sky-600 gen-loading-spinner-bounce-1" />
      <div className="bg-sky-600 gen-loading-spinner-bounce-2" />
      <div className="bg-sky-600 gen-loading-spinner-bounce-3" />
    </div>
  );
}
