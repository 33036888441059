import * as React from "react";

import GenButton from "./Button";

function parseInputStringForInt(inputString) {
  const digitMatch = parseInt(inputString.match(/\d+/)?.[0]);
  return isNaN(digitMatch) ? "" : digitMatch;
}

export default function GenMediaDuplicationTool(props) {
  const {
    onSuccess,
    onError,
    documentId,
  } = props;

  const [mediaAssetId, setMediaAssetId] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const submitFunction = () => {
    setIsProcessing(true);

    fetch(`/media_assets/${mediaAssetId}/duplicate`, {
      method: "POST",
      body: JSON.stringify({
        media_asset: {
          duration,
          document_id: documentId,
        },
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`) }

        return response.json();
      })
      .then(() => {
        if (onSuccess) { onSuccess(); }
        setIsProcessing(false);
      })
      .catch((error) => {
        alert("Error Duplicating Media");

        if (onError) { onError(error); }
        setIsProcessing(false);
      });
  };

  return (
    <div>
      <h3>Duplicate and Shorten Video:</h3>
      <label>Video Id:</label>
      <input
        type="text"
        name="title"
        value={mediaAssetId || ""}
        onChange={(e) => setMediaAssetId(parseInputStringForInt(e.target.value))}
      />

      <label>Duration in Seconds:</label>
      <input
        type="text"
        name="title"
        value={duration || ""}
        onChange={(e) => setDuration(parseInputStringForInt(e.target.value))}
      />

      <GenButton
        name="Duplicate"
        loading={isProcessing}
        disabled={!mediaAssetId}
        onClick={submitFunction}
      />
    </div>
  );
}
