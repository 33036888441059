function resetPasswordLink(e) {
  e.preventDefault();

  const returnValue = window.confirm("Are you sure you want to generate a password reset link? This will action will expire any previous password reset links.");
  if (!returnValue) { return; }

  const button = e.target;
  const url = button.dataset.generateResetPasswordUrl;

  fetch(url, {
    method: "PUT",
    headers: {
      "X-CSRF-Token": (document.querySelector("[name='csrf-token']") as HTMLMetaElement).content,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) { throw new Error(`${response.status} response`) }

      return response.json();
    })
    .then((result) => {
      alert(result.reset_url);
    })
    .catch((error) => {
      alert(`Error: ${error.message}`);
    });
}

function readyResetPasswordLink() {
  const resetPasswordButton = document.getElementById("reset_password_button");

  if (!resetPasswordButton) { return; }

  resetPasswordButton.removeEventListener("click", resetPasswordLink);
  resetPasswordButton.addEventListener("click", resetPasswordLink);
}

document.addEventListener("DOMContentLoaded", readyResetPasswordLink);
document.addEventListener("turbolinks:load", readyResetPasswordLink);
