import * as React from 'react';

import GenLoadingSpinner from './LoadingSpinner'

export default function GenButton(props) {
  const internalOnClick = (value) => {
    if (!props.disabled) {
      if (props.confirmMessage && !confirm(props.confirmMessage)) {
        return
      }

      if (props.href) {
        if (props.target === "_blank") {
          window.open(props.href, "_blank");
        } else {
          Turbolinks.visit(props.href)
        }
      } else {
        props.onClick(value);
      }
      value.stopPropagation();
    }
  };

  let classes = "gen-button";
  let spanClasses = "bg-sky-600 border border-sky-600 button-name"

  if (props.spanClasses) {
    spanClasses = `${props.spanClasses} ` + spanClasses;
  }

  if (props.disabled) {
    classes += " disabled cursor-not-allowed";
  }

  if (props.highlighted) {
    classes += " highlighted";

    if (!props.disabled) {
      spanClasses += " hover:bg-sky-500"
    }
  }

  if (props.destructive) {
    classes += " destructive"
  }

  if (props.small) {
    classes += " small";
  }

  if (props.alertColor) {
    classes += " alertColor";
  }

  if (props.bottom_margin) {
    classes += " bottom_margin";
  }

  if (props.className) {
    classes += ` ${props.className}`;
  }

  return (
    <div className={classes} onClick={internalOnClick}>
      <span className={spanClasses}>{props.loading ? <GenLoadingSpinner/> : props.name}</span>
    </div>
  );
}
