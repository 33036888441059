import * as React from 'react';

import DocBookmark from './Bookmark'
import DocListItem from './ListItem'

interface DocRegularListProps extends RegularListNode, EveryNodeArgs {};

export default function DocRegularList(props: DocRegularListProps) {
  let listItems = props.content.map(listItem => {
    return (
      <DocListItem
        key={listItem.uid}
        {...listItem}
        currentDocument={props.currentDocument}
        currentVersion={props.currentVersion}
        currentRoleDefinition={props.currentRoleDefinition}
        currentDocumentSetDocumentRelation={props.currentDocumentSetDocumentRelation}
        currentUser={props.currentUser}
        displayState={props.displayState}
        editState={props.editState}
      />
    );
  });

  return (
    <ul className="doc-regular-list">
      {listItems}
      <DocBookmark {...props} />
    </ul>
  );
}
