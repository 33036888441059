import * as React from 'react';

import classNames from "classnames";

import DocBookmark from "./Bookmark";
import DocInlineText from "./InlineText";

interface DocMultipleChoiceProps extends MultipleChoiceNode, EveryNodeArgs {};

export default function DocMultipleChoice(props: DocMultipleChoiceProps) {
  const {
    uid,
    content,
    multipleSelect,
    editState,
    graded,
    inactiveBoolean,
    currentUser,
    feedback,
  } = props;

  const onValueChanged = (e) => {
    const state = {[e.target.id]: {value: e.target.checked}};

    if (!multipleSelect) {
      content
        .filter((answerOption) => answerOption.value && answerOption.uid !== e.target.id)
        .forEach((answerOption) => { state[answerOption.uid] = {value: false}; });
    }

    currentUser.addState(state);
  };

  const answers = content.map((answer) => (
    <li key={answer.uid} className={classNames("doc-multiple-choice-answer", {answered: answer.value, [answer.answer ? "correct" : "incorrect"]: graded})}>
      { editState.isEditing && (
        <input
          type="checkbox"
          checked={answer.answer}
          onChange={() => { editState.handleKeyValueChange(answer.uid, "answer", !answer.answer) }}
        />
      )}
      <input
        type="checkbox"
        id={answer.uid}
        className="value-checkbox"
        disabled={inactiveBoolean}
        checked={answer.value}
        onChange={onValueChanged}
      />
      <label
        htmlFor={answer.uid}
      >
        <span className="doc-multiple-choice-answer-outer"><span className="doc-multiple-choice-answer-inner" /></span>
        <span className="doc-multiple-choice-answer-title">
          <DocInlineText content={answer.content} uid={answer.uid} contentKey="content" editState={editState} />
        </span>
        {graded && (answer.value || answer.answer) && (
          <span style={{marginLeft: "10px"}}>
            {answer.answer ? <i className="fa fa-check text-green-600"></i> : <i className="fa fa-times text-red-700"></i> }
          </span>
        )}
      </label>
    </li>
  ));

  let renderedFeedback;
  if (graded || editState.isEditing) {
    let feedbackAnswers = content.map((answer) => (
      <li key={answer.uid} className="doc-multiple-choice-answer">
        <DocInlineText content={answer.content} uid={answer.uid} contentKey="content" editState={editState} />
        <span style={{marginLeft: "10px"}}>
          {answer.answer ? <i className="fa fa-check text-green-600"></i> : <i className="fa fa-times text-red-700"></i> }
          {answer.feedback}
        </span>
      </li>
    ))

    let renderedExtraFeedback;
    if (editState.isEditing) {
      renderedExtraFeedback = (
        <textarea
          value={feedback}
          onChange={(e) => editState.handleKeyValueChange(uid, "feedback", e.target.value)}
          onClick={() => editState.setCurrentlyEditing(null)}
        />
      )
    } else if(feedback) {
      renderedExtraFeedback = feedback.split(/\r?\n/).map((splitFeedback, i) => (
        <p key={i}>
          {splitFeedback}
        </p>
      ))
    }

    renderedFeedback = (
      <div className="border-solid border-2 border-black p-3 mt-3">
        <h4>Correct Answers:</h4>
        {feedbackAnswers}
        <hr />
        {renderedExtraFeedback}
      </div>
    )
  }

  const instructions = multipleSelect ?
    <i>Select all that apply</i> :
    <i>Select the best answer</i>

  return (
    <ul className={classNames("doc-multiple-choice", {inactive: inactiveBoolean, graded})}>
      {instructions}
      {answers}
      {renderedFeedback}
      <DocBookmark {...props} />
    </ul>
  );
}
