import * as React from 'react';

interface DocSectionScheduleStateProps {
  hideUntil: Date | boolean;
};

export default function DocSectionScheduleStatus(props: DocSectionScheduleStateProps) {
  const {
    hideUntil,
  } = props

  let status
  let className = 'doc-section-schedule-status '
  if (hideUntil) {
    if (hideUntil instanceof Date) {
      if (hideUntil > new Date()) {
        status = `Hidden Until: ${(hideUntil.getMonth() + 1)}/${hideUntil.getDate()}/${hideUntil.getFullYear()}`
        className += "visible-after-label"
      } else {
        status = "Visible"
        className += "visible-label"
      }
    } else {
      status = "Hidden"
      className += "hidden-label"
    }
  } else {
    status = "Visible"
    className += "visible-label"
  }

  return <p className={className}>{status}</p>
}
