import * as React from 'react';

import DocInlineText from './InlineText'

interface DocListItemProps extends ListItemNode, EveryNodeArgs {};

export default function DocListItem(props: DocListItemProps) {
  const {
    content,
    uid,
    editState,
  } = props

  return (
    <li className="doc-list-item">
      <DocInlineText content={content} uid={uid} contentKey="content" editState={editState}/>
    </li>
  );
}
